import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import {
  addDays,
  addHours,
  differenceInDays,
  eachDayOfInterval,
  eachHourOfInterval,
  parseISO,
  set,
  startOfDay,
  startOfHour
} from 'date-fns';
import { cloneDeep, flatMap, isArray, isDate, isEqual, isNumber, isObject, isString, zip } from 'lodash-es';
import moment, { Duration, isMoment, Moment, unitOfTime } from 'moment';
import { DateRange } from 'moment-range';
import { BoundType, NumberRange } from 'range-ts';
import { temporalUnits } from './calendar-period.pipe';
import { TimePeriodTo } from './time-period';
import { normalizeToDate } from './utils';
import 'reflect-metadata';

const debug = false;

/**
 * Stores a static value using the provided metadatakey, derived from the provided factoryFn.
 * The value is only set on the object once using the factoryFn, if the object already has the key the existing value is used instead (using getOrDefault)
 */
class MetaDataCache<T, V> {
  private sourceReferenceKey: string;

  constructor(
    private key: string,
    private factoryFn: (source: T) => V
  ) {
    this.sourceReferenceKey = `${key}sourceReference`;
  }

  getOrDefault(source: T): V {
    if (Reflect.hasMetadata(this.key, source)) {
      if (debug) {
        // Check reference
        if (!isEqual(Reflect.getMetadata(this.sourceReferenceKey, source), source)) {
          console.error('Source was not equal, marking as miss');
        } else {
          // console.warn('cache hit')
          return Reflect.getMetadata(this.key, source);
        }
      } else {
        // console.warn('cache hit')
        return Reflect.getMetadata(this.key, source);
      }
    }
    // console.warn('cache miss');
    const result = this.factoryFn(source);
    Reflect.defineMetadata(this.key, result, source);
    if (debug) {
      Reflect.defineMetadata(this.sourceReferenceKey, cloneDeep(source), source);
    }
    return result;
  }
}

/**
 * Period defined by 2 Instants (or ISO formatted date strings)
 */
export abstract class TimeSlot {
  static startDateTimeValueOfCache = new MetaDataCache<TimeSlot, number>('startDateTimeValueOf', (value) => {
    if (value.startDateTime) {
      return parseISO(value.startDateTime).valueOf();
    } else {
      return null;
    }
  });
  static toDateTimeValueOfCache = new MetaDataCache<TimeSlot, number>('toDateTimeValueOf', (value) => {
    if (value.toDateTime) {
      return parseISO(value.toDateTime).valueOf();
    } else {
      return null;
    }
  });

  readonly startDateTime: string;
  readonly toDateTime: string;

  private static dateTimeValue(cache: MetaDataCache<any, number>, cacheTarget: any, value: string | number | Moment | Date): number | null {
    if (isString(value) && isObject(cacheTarget)) {
      return cache.getOrDefault(cacheTarget);
    } else if (isString(value)) {
      // Assume ISO string
      console.warn('Presuming ISO formatted date string', value);
      return parseISO(value).valueOf();
    } else if (isDate(value)) {
      return (value as Date).valueOf();
    } else if (isMoment(value)) {
      return (value as Moment).valueOf();
    } else if (isNumber(value)) {
      return value;
    }
  }

  private static startDateTimeValue(timeSlotOrArray: TimeSlot | any[]): number {
    const value =
      isArray(timeSlotOrArray) && timeSlotOrArray.length === 2 ? timeSlotOrArray[0] : (timeSlotOrArray as TimeSlot).startDateTime;

    const result = TimeSlot.dateTimeValue(TimeSlot.startDateTimeValueOfCache, timeSlotOrArray, value);

    if (result === null) {
      console.error('Failed to parse:', timeSlotOrArray);
      throw new Error('Could not parse TimeSlot to get startDateTimeValue');
    }

    return result;
  }

  private static toDateTimeValue(timeSlotOrArray: TimeSlot | any[]): number {
    const value = isArray(timeSlotOrArray) && timeSlotOrArray.length === 2 ? timeSlotOrArray[1] : (timeSlotOrArray as TimeSlot).toDateTime;
    const result = TimeSlot.dateTimeValue(TimeSlot.toDateTimeValueOfCache, timeSlotOrArray, value);

    if (result === null) {
      console.error('Failed to parse:', timeSlotOrArray);
      throw new Error('Could not parse TimeSlot to get toDateTimeValue');
    }

    return result;
  }

  /**
   * Api bsed on dateFns isEqual. Compares the valueOf of 2 timeSlots, must be exactly equal.
   * https://date-fns.org/v2.22.1/docs/isEqual
   *
   * Also supports [Date, Date] or [number, number] to facilitate .domain() results of d3
   */
  static isEqual(a: TimeSlot, b: TimeSlot): boolean {
    const aLower = TimeSlot.startDateTimeValue(a);
    const aUpper = TimeSlot.toDateTimeValue(a);

    const bLower = TimeSlot.startDateTimeValue(b);
    const bUpper = TimeSlot.toDateTimeValue(b);

    return aLower === bLower && aUpper === bUpper;
  }

  /**
   * Returns true if the time slots overlap in any way.
   * Note that start time is inclusive, end time is exclusive (closed-open)
   */
  static overlaps(a: TimeSlot, b: TimeSlot): boolean {
    return TimeSlot.toNumberRange(a).overlaps(TimeSlot.toNumberRange(b));
  }

  /**
   * Used to convert a time slot to a period filter object to use when calling list endpoints
   */
  static toPeriodFilter(timeSlot: TimeSlot): PeriodFilterPartial {
    return {
      startDate: timeSlot && moment(timeSlot.startDateTime).format('YYYY-MM-DD'),
      toDate: timeSlot && moment(timeSlot.toDateTime).format('YYYY-MM-DD')
    };
  }

  /**
   * Get the period covering the month of the provided value
   */
  static getMonthPeriod(value: Moment | string | Date): TimeSlot {
    return {
      startDateTime: moment(value).startOf('month').toISOString(),
      toDateTime: moment(value).add(1, 'month').startOf('month').toISOString()
    };
  }

  static getSmallestEncompassingTemporalUnit(period: TimeSlot): unitOfTime.All {
    return temporalUnits.find((temporalUnit) => {
      const startDateTime = moment(period.startDateTime);
      const toDateTime = moment(period.toDateTime);
      return (
        startDateTime.get(temporalUnit) === toDateTime.get(temporalUnit) &&
        startDateTime.diff(toDateTime, temporalUnit as unitOfTime.Diff) === 0
      );
    });
  }

  static toDuration(period: TimeSlot): Duration {
    return moment.duration(moment(period.startDateTime).diff(period.toDateTime));
  }

  static fromDuration(startDateTime: Moment | string, duration: Duration): TimeSlot {
    return {
      startDateTime: moment(startDateTime).toISOString(),
      toDateTime: moment(startDateTime).add(duration).toISOString()
    };
  }

  static toNumberRange(timeSlot: TimeSlot): NumberRange {
    const lowerEndPoint = TimeSlot.startDateTimeValueOfCache.getOrDefault(timeSlot);
    const upperEndPoint = TimeSlot.toDateTimeValueOfCache.getOrDefault(timeSlot);

    return NumberRange.closedOpen(lowerEndPoint, upperEndPoint);
  }

  /**
   * Map time periods on a given timeSlot as TimeSlots
   */
  static fromTimePeriodWithinTimeSlot(timePeriods: TimePeriodTo[], timeSlot: TimeSlot): TimeSlot[] {
    if (!timeSlot || !timePeriods || timePeriods.length === 0) {
      return [];
    }

    const daysOfInterval = eachDayOfInterval({
      start: new Date(TimeSlot.startDateTimeValueOfCache.getOrDefault(timeSlot)),
      end: new Date(TimeSlot.toDateTimeValueOfCache.getOrDefault(timeSlot))
    });

    if (!daysOfInterval?.length) {
      return [];
    }

    return flatMap(daysOfInterval, (day) => {
      return flatMap(timePeriods, (timePeriod) => {
        const [startHour, startMinute, startSecond] = timePeriod.startTime.split(':').map((input) => parseInt(input, 10));
        const [toHour, toMinute, toSecond] = timePeriod.toTime.split(':').map((input) => parseInt(input, 10));
        const startDateTimeDate = set(day, {
          hours: startHour,
          minutes: startMinute,
          seconds: startSecond
        });
        let toDateTimeDate = set(day, {
          hours: toHour,
          minutes: toMinute,
          seconds: toSecond
        });
        if (toHour === 0 && toMinute === 0 && toSecond === 0) {
          toDateTimeDate = addDays(toDateTimeDate, 1);
        }

        return {
          startDateTime: startDateTimeDate.toISOString(),
          toDateTime: toDateTimeDate.toISOString()
        };
      });
    });
  }

  static fromNumberRange(numberRange: NumberRange): TimeSlot {
    if (!numberRange) {
      return null;
    }

    if (numberRange.lowerBoundType !== BoundType.CLOSED || numberRange.upperBoundType !== BoundType.OPEN) {
      console.warn('Could not convert non closed numberRange to timeSlot');
      return null;
    }

    return {
      startDateTime: moment(numberRange.lowerEndpoint.valueOf()).format('YYYY-MM-DD'),
      toDateTime: moment(numberRange.upperEndpoint.valueOf()).format('YYYY-MM-DD')
    };
  }

  static fromNumberRangeWithTime(numberRange: NumberRange): TimeSlot {
    if (!numberRange) {
      return null;
    }

    if (numberRange.lowerBoundType !== BoundType.CLOSED || numberRange.upperBoundType !== BoundType.OPEN) {
      console.warn('Could not convert non closed numberRange to timeSlot');
      return null;
    }

    return {
      startDateTime: moment(numberRange.lowerEndpoint.valueOf()).format('YYYY-MM-DDTHH:mm'),
      toDateTime: moment(numberRange.upperEndpoint.valueOf()).format('YYYY-MM-DDTHH:mm')
    };
  }

  /**
   * Returns a list of timeSlots representing the start of each time period.
   * TODO write tests for varous timeunits and/or create separate functions for time untis based on their rounding
   */
  static asTimeUnit(timeSlot: TimeSlot, timeUnit: unitOfTime.Base, step?: number): TimeSlot[] {
    if (!timeSlot) {
      return [];
    }

    const dateRange = new DateRange(
      moment(timeSlot.startDateTime).startOf('second').startOf(timeUnit),
      moment(timeSlot.toDateTime).endOf('second').endOf(timeUnit)
    );

    return [...dateRange.by(timeUnit, { step })].map((timeUnitMoment) =>
      TimeSlot.toTimeSlot(timeUnitMoment, moment(timeUnitMoment).add(step || 1, timeUnit))
    );
  }

  static asHourPeriods(timeSlot: TimeSlot): TimeSlot[] {
    if (!timeSlot) {
      return [];
    }

    const hoursOfInterval = eachHourOfInterval({
      start: new Date(TimeSlot.startDateTimeValueOfCache.getOrDefault(timeSlot)),
      end: new Date(TimeSlot.toDateTimeValueOfCache.getOrDefault(timeSlot))
    });

    if (!hoursOfInterval?.length) {
      return [];
    }

    return zip(hoursOfInterval.slice(0, -1), hoursOfInterval.slice(1)).map(([startDateTime, toDateTime]) => {
      return {
        startDateTime: startDateTime.toISOString(),
        toDateTime: toDateTime.toISOString()
      };
    });
  }

  /**
   * Return the timeSlot surrounding the provided moment
   */
  static fromMomentAndTimeUnit(
    targetMoment: Moment | string,
    timeUnit: unitOfTime.StartOf,
    paddingTimeUnit: unitOfTime.StartOf = timeUnit
  ): TimeSlot {
    return TimeSlot.toTimeSlot(
      moment(targetMoment).startOf(timeUnit).startOf(paddingTimeUnit),
      moment(targetMoment).endOf(timeUnit).endOf(paddingTimeUnit)
    );
  }

  static toTimeSlot(startDateTime: Moment | string, toDateTime: Moment | string): TimeSlot {
    return {
      startDateTime: moment(startDateTime).toISOString(),
      toDateTime: moment(toDateTime).toISOString()
    };
  }

  static daysDiff(period: TimeSlot): number {
    return differenceInDays(parseISO(period.toDateTime), parseISO(period.startDateTime));
  }

  /**
   * Return a TimeSlot spanning the entire current day
   */
  static today(): TimeSlot {
    return {
      startDateTime: startOfDay(new Date()).toISOString(),
      toDateTime: startOfDay(addDays(new Date(), 1)).toISOString()
    };
  }

  /**
   * Create a single hour period timeSlot that contains the given Date
   */
  static fromDateAsHourStart(date: Date | string): TimeSlot {
    date = normalizeToDate(date);
    if (!date) {
      return null;
    }

    date = startOfHour(date);

    return {
      startDateTime: date.toISOString(),
      toDateTime: addHours(date, 1).toISOString()
    };
  }
}

export interface PeriodFilterPartial {
  startDate: string;
  toDate: string;
}

/**
 * Transforms a timeslot to a timeslot with the toDate 'exclusive' (open).
 *
 * I.e. A timeslot of '01-06-2020 | 01-07-2020' will be converted into '01-06-2020 | 30-06-2020'
 *
 */
@Pipe({
  name: 'timeSlotDays'
})
export class TimeSlotDaysPipe extends DatePipe implements PipeTransform {
  // TODO do not extend DatePipe
  // @ts-ignore
  transform(value: TimeSlot, format?: string, timezone?: string, locale?: string): string | null {
    const startDate = super.transform(value.startDateTime, format, timezone, locale);
    const toDateMinusSecond = moment(value.toDateTime).startOf('day').subtract(1, 'second').valueOf();
    const toDate = super.transform(toDateMinusSecond, format, timezone, locale);
    return `${startDate} | ${toDate}`;
  }
}
