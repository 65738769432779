export const MANAGE_CUSTOMER = 'manage_customer';
export const VIEW_CUSTOMER = 'view_customer';
export const VIEW_GRID_POINT = 'view_grid_point';
export const VIEW_DEVICE = 'view_device';
export const VIEW_INTRADAY = 'view_intra_day';
export const VIEW_CONTROL = 'view_control';
export const VIEW_RECIPIENT = 'view_recipient';
export const VIEW_UNAVAILABILITY = 'view_unavailability';
export const VIEW_SERVICE_AGREEMENT = 'view_service_agreement';
export const VIEW_MEASUREMENT_DATA = 'view_measurement_data';
export const VIEW_MEASUREMENT_UPLOAD_DATA = 'view_measurement_upload_data';
export const VIEW_BRP = 'view_brp';
export const VIEW_TSO_AGREEMENT = 'view_tso_agreement';
export const VIEW_POOL = 'view_pool';
export const VIEW_OPERATION = 'view_operation';
export const VIEW_MESSAGES = 'view_messages';
export const MANAGE_USERS = 'manage_users';
export const MANAGE_USER_GROUPS = 'manage_user_groups';
export const MANAGE_EXTERNAL_USERS = 'manage_external_users';
export const VIEW_ADMIN = 'view_admin';
export const VIEW_OPERATION_NOTIFICATION = 'view_operation_notification';
export const VIEW_INCIDENT_RESERVE_INVOICE_DATA = 'view_incident_reserve_invoice_data';
export const VIEW_AGGREGATED_POOL = 'view_aggregated_pool';
export const VIEW_MEASUREMENT_COMPANY = 'view_measurement_company';
export const VIEW_DISPATCH_SCHEDULE = 'view_dispatch_schedule';
export const VIEW_POOL_AVAILABILITY = 'view_pool_availability';
export const VIEW_IMBALANCE_FORECAST = 'view_imbalance_forecast';
export const MANAGE_R3_AVAILABILITY = 'manage_r3_availability';
export const VIEW_BATTERY = 'view_battery';
export const VIEW_BATTERY_MEASUREMENT_DATA = 'view_battery_measurement_data';
export const VIEW_ANALOG_INPUT = 'view_analog_input';
export const VIEW_INTRA_DAY = 'view_intra_day';
export const VIEW_FLEX_OVERVIEW = 'view_flex_overview';
export const VIEW_IMBALANCE_CORRECTION = 'view_imbalance_correction';
export const VIEW_INVOICE_RESULTS = 'view_invoice_results';
export const VIEW_INTRA_DAY_DEAL_REPORTING = 'view_intra_day_deal_reporting';
export const VIEW_INTRA_DAY_IDCONS_ORDER = 'view_intra_day_idcons_order';
export const MANAGE_INTRA_DAY_IDCONS_ORDER = 'manage_intra_day_idcons_order';
export const VIEW_INTRA_DAY_REM_ORDER = 'view_intra_day_rem_order';
export const MANAGE_INTRA_DAY_REM_ORDER = 'manage_intra_day_rem_order';
export const MANAGE_API_KEY = 'manage_api_key';
export const MANAGE_USER_PREFERENCES = 'manage_user_preferences';
export const USE_ADMIN_PORTAL = 'use_admin_portal';
export const MANAGE_ADMIN_ANNOUNCEMENTS = 'manage_admin_announcements';
