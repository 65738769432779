import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { SubscriptSizing } from '@angular/material/form-field';
import { endOfYear, startOfYear } from 'date-fns';
import { normalizeToDate } from '../../../core/date-time/utils';
import { DateRangeFixedPeriodCalendarHeaderComponent } from '../date-range-fixed-period-date-picker-header/date-range-fixed-period-calendar-header.component';
import { AVAILABLE_PERIOD_UNITS, PeriodUnit } from '../default-period';

@Component({
  selector: 'ph-flex-date-range-fixed-period-month',
  templateUrl: './date-range-fixed-period-month.component.html',
  styleUrls: ['./date-range-fixed-period-month.component.scss'],
  providers: [
    {
      provide: AVAILABLE_PERIOD_UNITS,
      useValue: [PeriodUnit.MONTH, PeriodUnit.YEAR]
    }
  ]
})
export class DateRangeFixedPeriodMonthComponent {
  startView = 'year';

  @Input()
  label: string;

  @Input()
  control: UntypedFormControl;

  @Input()
  subscriptSizing: SubscriptSizing = 'fixed';

  private _min: Date | null;
  private _max: Date | null;

  @Input()
  set min(value: Date | string | null) {
    if (value) {
      this._min = startOfYear(normalizeToDate(value));
    }
  }

  get min(): Date | null {
    return this._min;
  }

  @Input()
  set max(value: Date | string | null) {
    if (value) {
      this._max = endOfYear(normalizeToDate(value));
    }
  }

  get max(): Date | null {
    return this._max;
  }

  calendarHeaderComponent = DateRangeFixedPeriodCalendarHeaderComponent;

  monthSelected(event: any, datePicker: MatDatepicker<any>): void {
    this.control?.setValue(normalizeToDate(event));
    datePicker.close();
  }
}
